// auth.js
//   tools for managing user authentication


//
// constants

let serverErrorMsg = 'Server error. Please try again later or contact us at help@6pages.com for assistance';
let connectionErrorMsg = 'Error. Please check your internet connection and try again.';


const loginRequestUrl = {
    site: process.env.SITE_SESSION_API_URL,
    user: process.env.USER_SESSION_API_URL
};






//
// request helpers

function tokenQuery (email, password, type = "")
{
    const query = type !== "" ?
          '( email: "' + email + '", password: "' + password + '", type: "' + type + '" )'
          : '( email: "' + email + '", password: "' + password + '" )';
    const projection = '{ key value max_age }'; // NOTE: graphql handler requires all 3 to form cookies
    const request = '{ sessionToken ' + query + ' ' + projection + ' }';
    return request;
    //return '{ "query": "' + JSON.stringify(request) + '" }';
}

function sendData (url,
                   data,
                   successCallback,
                   failureCallback,
                   serverErrorCallback,
                   connectErrorCallback)
{
    var XHR = new XMLHttpRequest();
    XHR.open('POST', url);
    XHR.setRequestHeader('Content-Type', 'application/json');
    XHR.withCredentials = true;

    XHR.addEventListener('load', function(event) {
        let status = this.status;
        if (status === 200) 
            successCallback(this.response);
        else if (status === 403)
            failureCallback(event);
        else
            serverErrorCallback(this);
    });

    XHR.addEventListener('error', function(event) {
        connectErrorCallback(event);
    });

    try {
        XHR.send(data);
    }
    catch (error) {
        serverErrorCallback(error);
    }
}



//
// interfaces


function makeLoginRequest (email,
                           password,
                           url,
                           successCallback,
                           failureCallback,
                           serverErrorCallback = null,
                           errorCallback = null,
                           type = "")
{
    let body = tokenQuery(email, password, type);
    
    sendData(url,
             body,
             successCallback,
             function ()
             {
                 failureCallback('Invalid email or password.');
             },
             function (event)
             {
                 serverErrorCallback ? serverErrorCallback(serverErrorMsg) : failureCallback(serverErrorMsg);
             },
             function (event)
             {
                 errorCallback ? errorCallback(connectionErrorMsg) : failureCallback(connectionErrorMsg);
             });
}


export { connectionErrorMsg, tokenQuery, loginRequestUrl, makeLoginRequest };
