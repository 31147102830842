import React from 'react';
import { getIn } from '../../utils/functional';
import { rhythm } from '../../utils/typography';
import { loginRequestUrl, makeLoginRequest } from '../../utils/auth.js';
import { redirect } from '../../utils/redirect.js';
import logo_blk from '../../components/header/logo_blk.svg';



const requestSuccess = function (responseBody)
{
    const body = JSON.parse(responseBody);
    if ( body && getIn(body, ['data', 'sessionToken']) )
    {
        redirect('/');
        return;
    }
    alert(responseBody);
};


//
// component


class LoginForm extends React.Component
{
    constructor (props)
    {
        super(props);
        this.state = {
            email: {value: ''},
            password: {value: ''}
        };

        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);        
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangeEmail (event)
    {
        this.setState({email: {value: event.target.value}});
    }

    handleChangePassword (event)
    {
        this.setState({password: {value: event.target.value}});
    }
    
    handleSubmit (event)
    {
        event.preventDefault();
        makeLoginRequest(
            this.state.email.value,
            this.state.password.value,
            loginRequestUrl.site,
            requestSuccess,
            alert,
            null,
            null,
            "site");
    }
    
    render ()
    {
        return (
            <React.Fragment>
              <form
                onSubmit={this.handleSubmit}
                style={{ fontFamily: `"Overpass", sans-serif` }}
                >

                <div
                  style={{
                      display: `flex`,
                      flexDirection: `column`,
                      justifyContent: `space-between`,
                      alignItems: `flex-end`
                  }} >

                  <div
                    style={{
                        display: `flex`,
                        flexDirection: `row`,
                        justifyContent: `center`,
                        alignItems: `center`
                    }} >
                    
                    <div
                      style={{ margin: `0 7px 0 0` }} >
                      Email
                    </div>

                    <input
                      type='text'
                      aria-label='Email address'
                      value={this.state.email.value}
                      onChange={this.handleChangeEmail}
                      style={{ textAlign: `center` }} />
                    
                  </div>

                  <div
                    style={{
                        marginTop: `10px`,
                        display: `flex`,
                        flexDirection: `row`,
                        justifyContent: `center`,
                        alignItems: `center`
                    }} >
                    
                    <div
                      style={{ margin: `0 7px 0 0` }} >
                      Password
                    </div>

                    <input
                      type='password'
                      aria-label='Password'
                      value={this.state.password.value}
                      onChange={this.handleChangePassword}
                      style={{ textAlign: `center` }} />
                    
                  </div>
                  
                </div>

                
                <center
                  style={{ marginTop: `20px` }} >
                  <input
                    type='submit'
                    aria-label='Login'
                    value={ `Login to ` + process.env.HOSTNAME } />
                </center>
                
              </form>
            </React.Fragment>
        );
    }
}

class Login extends React.Component
{
    render()
    {
        return (
            <div style={{
                     margin: `${rhythm(2)} auto`,
                     display: `flex`,
                     flexDirection: `column`,
                     justifyContent: `center`,
                     alignItems: `center`
                 }} >

              <img
                src={ logo_blk }
                alt='6 Pages'
                style={{
                    width: `200px`,
                    height: `32px`,
                    margin: `2em`,
                    objectFit: `contain`,
                    border: 0,
                    padding: 0
                }} />
              
              <LoginForm />
              
            </div>
        );
    }
}


export default Login;
